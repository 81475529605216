/**
 * @file Login and Logout Methods.
 * @copyright 2004-2019 United Planet GmbH, Freiburg - All Rights Reserved.
 */

/* globals ix, oTTP_Login, Helper, handleLoginResponse, ErrorHandler, Browser */

/**
 * @class
 * @ignore
 * @description Management of the login-procedures.
 * Communicates with the server in order to request for configured login-modules and expected login-tokens.
 */
function upLogin() {
    this.aConfig = [];
    this.callback;
    this.currentConfig = 0;
    this.currentStatus = "";
    this.aCredentials = [];
    this.strModuleDirectory = "intrexxauth";
    this.isAnonymous = true;
    this.tooltip = typeof oTTP_Login !== "undefined" && oTTP_Login !== null ? oTTP_Login : null; // eslint-disable-line camelcase
    this.isOpenedInTooltip = function () {
        return this.tooltip !== null;
    };
    this.getTooltipKey = function (target) {
        var tooltip = ix.tooltip.closest(target || document.UpLoginForm || document.frmLogin || "#oTTP_Login");
        return tooltip ? tooltip.key : null;
    };
    this.closeTooltip = function (e) {
        if (oTTP_Login && typeof oTTP_Login.closePopup === "function") {
            oTTP_Login.closePopup();
        } else if (e.data.login.getTooltipKey(e.target)) {
            ix.tooltip.close(e.data.login.getTooltipKey(e.target));
        }
    };
    this.strSuffix = this.isOpenedInTooltip() ? "_TTP" : "";
    this.ctrls = {};
}

/**
 * @ignore
 * @param {String} moduleDirectory The current directory for getting login-methods.
 * @param {Object} [container=$(".Login_Container:eq(0)")] The Login-Container.
 * @return {upLogin}
 * @description Initialize the login class.
 */
upLogin.prototype.init = function (moduleDirectory, container, callback) {
    this.strModuleDirectory = moduleDirectory;
    this.callback = callback;
    if (typeof container !== "undefined" && container instanceof jQuery) {
        container = container.eq(0);
    } else {
        // fallback if no container was given
        container = $(".Login_Container:eq(0)");
    }

    this.ctrls = {
        container: container,
        inputs: {
            name: container.find('input[name="fr_LoginName"]'),
            pwd: container.find('input[name="fr_Password"]'),
            domain: container.find('[name="fr_LoginDomain"]'),
        },
        buttons: {
            login: container.find('input[name="Login"]'),
            logout: container.find('input[name="Logout"]'),
            pwdtoggle: container.find('a[id="ID_PWDToggle"]'),
            pwdchange: container.find('a[name="PWDLink"]'),
            domainclear: container.find('span[id="ID_DOMAINClear"]'),
            cancel: container.find('input[name="Cancel"]'),
        },
        notifications: {
            success: container.find(".Login_InfoBox.InfoBox_Success"),
            warning: container.find(".Login_InfoBox.InfoBox_Warning"),
            error: container.find(".Login_InfoBox.InfoBox_Error"),
        },
    };
    // determine the form from input
    this.ctrls.form = $(ix.util.getForm(this.ctrls.inputs.name.get(0)));

    // add handler to the controls
    this.ctrls.inputs.name.on(
        "keydown",
        {
            login: this,
        },
        function (e) {
            if (!e.data.login.userValid(e)) {
                e.stopImmediatePropagation();
            }
        }
    );

    this.ctrls.buttons.pwdtoggle.on(
        "click",
        {
            login: this,
        },
        function (e) {
            return upLogin.togglePwd(e);
        }
    );

    this.ctrls.buttons.pwdchange.on(
        "click",
        {
            login: this,
        },
        function (e) {
            return e.data.login.changePwd(e);
        }
    );

    this.ctrls.buttons.domainclear.on(
        "click",
        {
            login: this,
        },
        function (e) {
            return e.data.login.clearDomainSelection(e);
        }
    );

    this.ctrls.buttons.logout.on(
        "click",
        {
            login: this,
        },
        function () {
            return upLogin.logout();
        }
    );

    this.ctrls.buttons.login.on(
        "click",
        {
            login: this,
        },
        function (e) {
            e.preventDefault();
            return e.data.login.ctrls.form.trigger("login");
        }
    );

    this.ctrls.form.on(
        "login",
        {
            login: this,
        },
        function (e) {
            e.preventDefault();
            var error = e.data.login.ctrls.notifications.error;
            if (e.data.login.getUserName().length) {
                error.fadeOut("fast", function () {
                    return e.data.login.login();
                });
            } else {
                error.fadeIn("fast", function () {
                    return false;
                });
            }
        }
    );

    this.ctrls.buttons.cancel.on(
        "click",
        {
            login: this,
        },
        function (e) {
            e.data.login.strSuffix = "";
            if (e.data.login.isOpenedInTooltip()) {
                e.data.login.closeTooltip(e);
            }
        }
    );

    this.ctrls.inputs.pwd.add(this.ctrls.inputs.name).on(
        "keydown",
        {
            login: this,
        },
        function (e) {
            upLogin.checkCapsLock(e);
        }
    );

    return this;
};

/**
 * @ignore
 * @return {Boolean} success
 * @description Loop trough the current login configurations.
 */
upLogin.prototype.login = function () {
    try {
        this.storeInformation();
        for (var i = 0, len = this.aConfig.length; i < len; i++) {
            this.currentConfig = i;
            this.aCredentials = [];

            if (this.hasToken("urn:schemas-unitedplanet-de:login-token:windows-authentication:user-name")) {
                continue;
            }

            if (this.hasToken("urn:schemas-unitedplanet-de:login-token:domain-name")) {
                this.setCredentials("urn:schemas-unitedplanet-de:login-token:domain-name", this.getDomain());
            }

            if (this.hasToken("urn:schemas-unitedplanet-de:login-token:user-name")) {
                this.setCredentials("urn:schemas-unitedplanet-de:login-token:user-name", this.getUserName());
            }

            if (this.hasToken("urn:schemas-unitedplanet-de:login-token:password")) {
                this.setCredentials("urn:schemas-unitedplanet-de:login-token:password", this.getPassword());
            }

            this.callLoginHandler();
            if (this.currentStatus === "success" && !this.isAnonymous) {
                return true;
            }
        }
        if (this.currentStatus !== "success" || this.isAnonymous) {
            this.setError();
            return false;
        }
    } catch (exception) {
        console.error(exception);
    }
};

/**
 * @ignore
 * @description Build an array with the token-name / value pairs for the current configuration.
 * @param {String} token The token.
 * @param {String} value The value.
 */
upLogin.prototype.setCredentials = function (token, value) {
    this.aCredentials.push(token + "||" + value);
};

/**
 * @ignore
 * @description Checks if a token is present.
 * @param {String} token The token.
 * @return {Boolean}
 */
upLogin.prototype.hasToken = function (token) {
    if (this.aConfig.length > this.currentConfig) {
        var config = this.aConfig[this.currentConfig];
        for (var i = 0, len = config.length; i < len; i++) {
            if (config[i] === token) {
                return true;
            }
        }
    }
    return false;
};

/**
 * @ignore
 * @description Make the AJAX request in order to login.
 */
upLogin.prototype.callLoginHandler = function () {
    var credentials = Helper.doCharStuffing(this.aCredentials);
    var UrlHelper = ix.util.UrlHelper;
    var url = UrlHelper.getBaseUrl("internal/system/vm/html/login/" + this.strModuleDirectory + "/getlogin.vm", url);
    // url = "/internal/system/vm/html/login/" + this.strModuleDirectory + "/getlogin.vm";
    // do NEVER change to GET, login must be POST!
    $.ajax({
        async: false,
        method: "POST",
        url: url,
        context: this,
        data: {
            fr_Credentials: credentials,
        },
        success: function (data) {
            var CSRFToken = data.CSRFToken;
            var status = data.status;
            var isAnonymous = data.anonymous === 1;
            var loginSuccessfull = status === "success" && !isAnonymous;

            ix.session.CSRFToken = CSRFToken;
            this.isAnonymous = isAnonymous;
            window.oUp.bIsAnonymous = isAnonymous;
            this.currentStatus = status;

            if (!loginSuccessfull) {
                return;
            } else {
                handleLoginResponse(status, data, this.ctrls.container);
            }
        },
        error: function (jqXHR) {
            ErrorHandler.handle(jqXHR);
        },
    });
};

/**
 * @ignore
 * @param {Array<String>} token
 * @description Make the AJAX request getting the token.
 */
upLogin.prototype.setToken = function (token) {
    // filter empty tokens due to prevent a login attempt (which will be always fail)
    if (token && token.length) {
        this.aConfig.push(token);
    }
};

/**
 * @ignore
 * @description Hide or show the error-message.
 */
upLogin.prototype.setError = function () {
    var error = this.ctrls.notifications.error;

    if (error.get(0).getAttribute("aria-hidden") === "true") {
        error.get(0).setAttribute("aria-hidden", "false");
    } else if (error.get(0).getAttribute("aria-hidden") === "false") {
        error.get(0).setAttribute("aria-hidden", "true");
    }

    error.fadeOut("fast").fadeIn("fast");
};

/**
 * @ignore
 * @return {String} userName The given user-name.
 */
upLogin.prototype.getUserName = function () {
    return this.ctrls.inputs.name.val().trim();
};

/**
 * @ignore
 * @return {String} password The given password.
 */
upLogin.prototype.getPassword = function () {
    return this.ctrls.inputs.pwd.val();
};

/**
 * @ignore
 * @return {String} domain The current selected or the fix domain, if specified otherwise an empty string.
 */
upLogin.prototype.getDomain = function () {
    return this.ctrls.inputs.domain.val() || "";
};

/**
 * @ignore
 * @param {JQueryEventObject} e
 * @description Check if caps lock activated and hide or show the warning-message.
 */
upLogin.checkCapsLock = function (e) {
    var evnt = e.originalEvent || event || window.event;
    var capsLock = evnt.getModifierState && evnt.getModifierState("CapsLock");
    var warning;

    if (e.data) {
        // upLogin
        warning = e.data.login.ctrls.notifications.warning;
    } else if (e.target.offsetParent && e.target.offsetParent.id) {
        // pwdchange
        warning = $("#" + e.target.offsetParent.id + " .Login_InfoBox.InfoBox_Warning");
    } else {
        // fallback
        warning = $(".Login_InfoBox.InfoBox_Warning");
    }

    if (warning) {
        if (capsLock) {
            warning.fadeIn("fast");
        } else {
            warning.fadeOut("fast");
        }
    }
};

/**
 * @ignore
 * @return {Promise}
 * @description Logout current session.
 */
upLogin.logout = function () {
    var url = ix.util.getBaseUrl("internal/system/vm/html/login/getLogout.vm");

    return $.ajax({
        url: url,
        method: "POST",
        success: function () {
            var url = "/loggedout.html";
            if (self.oUp && self.oUp.oFormatInfo) {
                url = Helper.setUrlValueByParam("rq_Lang", self.oUp.oFormatInfo.lang, url);
                url = Helper.setUrlValueByParam("rq_Layout", self.oUp.oFormatInfo.layoutDir, url);
                url = Helper.setUrlValueByParam("rq_Portal", self.oUp.portalName, url);
            }
            top.location.replace(url);
        },
        error: function () {
            ErrorHandler.notify("Unexpected exception while processing logout.");
        },
    });
};

/**
 * @ignore
 * @param {JQueryEventObject} e
 * @description Show/hide the password input field
 * @return {upLogin}
 */
upLogin.togglePwd = function (e) {
    if (e && e.target) {
        var pwToggle = $(e.target);
        var pwInput = $(e.target).siblings("input")[0];

        if (pwInput.type == "password") {
            pwInput.type = "text";
            pwToggle.removeClass("passwordShow-icon");
            pwToggle.addClass("passwordHide-icon");
        } else {
            pwInput.type = "password";
            pwToggle.removeClass("passwordHide-icon");
            pwToggle.addClass("passwordShow-icon");
        }
    } else {
        console.debug("» No Password-Input found!");
    }
    return this;
};

/**
 * @ignore
 * @description Jump to the password change page.
 */
upLogin.prototype.changePwd = function () {
    ix.loader.stage({
        vm: "internal/system/vm/html/login/" + this.strModuleDirectory + "/pwd.vm",
        html: document.querySelector('[name="PWDLink"]'),
        fup: self.oUp.oFuncPart,
        reloadByChange: true,
        windowSettings: {
            alignment: "middle_center",
            avoidMouse: true,
            closeByButton: false,
            closeByClick: false,
            closeByEsc: false,
            key: "oTTP_LoginCHG",
            modal: true,
            position: "window",
            title: ix.text.i18n.get("CHANGEPASSWORDHEADER"),
        },
    });
};

/**
 * @ignore
 * @description Clear the domain selection (select default/empty value).
 */
upLogin.prototype.clearDomainSelection = function () {
    if (this.ctrls.inputs.domain[0]) {
        this.ctrls.inputs.domain[0].selectedIndex = 0;
    }
};

/**
 * @ignore
 * @param {Event} e
 * @return {Boolean}
 * @description Fill in "Administrator" when "CTRL" or "ALT" + "." is pressed.
 */
upLogin.prototype.userValid = function (e) {
    self.oUp.oEvent = Browser.ie ? $.extend({}, e || window.event) : e || window.event;
    if (Browser.getEventKeyCode) {
        var keyCode = Browser.getEventKeyCode();
        if ((self.oUp.oEvent.altKey || self.oUp.oEvent.ctrlKey) && (keyCode === 190 || keyCode === 46)) {
            this.ctrls.inputs.name.val("Administrator");
            this.ctrls.inputs.pwd.val("").focus();
            this.ctrls.inputs.domain.val(this.ctrls.inputs.domain.children(0).val());
            return false;
        }

        if (keyCode === 13 && this.ctrls.inputs.name.val() !== "" && this.ctrls.inputs.pwd.val() === "") {
            this.ctrls.inputs.pwd.focus();
            return false;
        }
    }
    return true;
};

/**
 * @ignore
 * @description Call login.
 */
upLogin.prototype.triggerLogin = function () {
    if (this.ctrls.buttons.login.length > 0) {
        this.ctrls.buttons.login.click();
    } else {
        this.login();
    }
};

/**
 * @ignore
 * @description Fill in the last username and domain.
 * @return {upLogin}
 */
upLogin.prototype.fillInformation = function () {
    var $name = this.ctrls.inputs.name;
    var $domain = this.ctrls.inputs.domain;
    var name = "";
    var domain = "";
    try {
        // use webstorage due it is not transfered to server
        var prefix = ix.util.getBaseUrl() || "";
        if (prefix.length > 0 && prefix.indexOf("/") === 0) {
            prefix = prefix.substring(1, prefix.length);
        }
        prefix = prefix.replace(/\//g, ".");
        name = localStorage.getItem(prefix + "loginName") || "";
        domain = localStorage.getItem(prefix + "loginDomain") || "";
    } catch (e) {
        // OK... User disabled localStorage or Browser is a granny
        // ugly and deprecated possibility using cookies is expensive
        name = Helper.getCookieValueByParam("loginName") || "";
        domain = Helper.getCookieValueByParam("loginDomain") || "";
    }
    if ($name.val() === "" && name !== "") {
        $name.val(name);
    }
    if ($domain.val() === "" && domain !== "") {
        $domain.val(domain);
    }
    return this;
};

/**
 * @ignore
 * @description Set the focus to user-field or password-field.
 * @return {upLogin}
 */
upLogin.prototype.setFocus = function () {
    var $name = this.ctrls.inputs.name;
    var $pwd = this.ctrls.inputs.pwd;
    if ($name.length !== 0 || $pwd.length !== 0) {
        this.fillInformation();
        if ($name.val() === "") {
            $name.focus();
        } else {
            $pwd.focus();
        }
    }
    return this;
};

/**
 * @ignore
 * @description Stores username and domain.
 * @return {upLogin}
 */
upLogin.prototype.storeInformation = function () {
    // remove possible cookies from prior sessions
    Helper.deleteCookie("loginName");
    Helper.deleteCookie("loginDomain");

    var name = this.getUserName();
    var domain = this.getDomain();
    try {
        // use webstorage due it is not transfered to server
        var prefix = ix.util.getBaseUrl() || "";
        if (prefix.length > 0 && prefix.indexOf("/") === 0) {
            prefix = prefix.substring(1, prefix.length);
        }
        prefix = prefix.replace(/\//g, ".");
        localStorage.setItem(prefix + "loginName", name);
        localStorage.setItem(prefix + "loginDomain", domain);
    } catch (e) {
        // OK... User disabled localStorage or Browser is a granny
        // ugly and deprecated possibility using cookies is expensive
        Helper.setCookie("loginName", name, true);
        Helper.setCookie("loginDomain", domain, true);
    }
    return this;
};

/**
 * @ignore
 * @param {String} moduleSubDir
 * @return {upLogin}
 * @description Change the password for the current user.
 */
window.changePassword = function (moduleSubDir) {
    var login = new upLogin();

    if (login.strModuleDirectory !== moduleSubDir) {
        login.init(moduleSubDir);
    }
    login.changePwd();

    return login;
};
